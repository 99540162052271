import React, {useState} from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/lora";
import "@fontsource/montserrat";

function Layout({ children }) {
  const settings = {
    autoplay: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const [showNav, setShowNav] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="leading-normal tracking-normal text-white">

          {/* Navigation */}
          <nav id="header" className="w-full z-30 top-0 text-white bg-white shadow">

            <div className="w-full container mx-auto lg:flex items-center justify-between mt-0 py-2">

              <div className="inline-block lg:flex items-center w-1/2">
                <a className="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-gray-800 inline-block lg:block" href="/">
                  <StaticImage src="../images/logo-marina-trapp.png" alt="Logo" className="m-auto lg:m-0 md:w-2/3 lg:w-1/2 xl:w-1/3" />
                </a>
              </div>

              <div className="inline-block lg:hidden m-8 float-right ">
                <button id="nav-toggle"
                  className="w-full mt-2 text-black p-4 z-20 bg-white inline-block" onClick={() => setShowNav(!showNav)} >
                  <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                </button>
              </div>

              <div className={`w-full flex-grow flex lg:items-center lg:w-auto lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20 ${showNav ? "" : "hidden"}`} id="nav-content">
                <ul className="list-reset lg:flex justify-end flex-1 items-center">
                  <li className="mr-3">
                    <a className="inline-block py-2 px-4 text-black font-bold no-underline" href="/#start">Start</a>
                  </li>
                  <li className="mr-3">
                    <a className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                       href="/#therapie">Therapie</a>
                  </li>
                  <li className="mr-3 flex-shrink-0">
                    <a className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                       href="/#about-me">Über mich</a>
                  </li>
                  <li className="mr-3">
                    <a className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                       href="/#kontakt">Kontakt</a>
                  </li>
                  <li className="mr-3">
                    <a className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                       href="/#news">News</a>
                  </li>
                </ul>
              </div>
            </div>

            <hr className="border-b border-gray-100 opacity-25 my-0 py-0"/>
          </nav>

          {children}
        </div>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
